import { default as indexpr2YVs6ZwdMeta } from "C:/WORK/gits/jovsky/pages/index.vue?macro=true";
export default [
  {
    name: indexpr2YVs6ZwdMeta?.name ?? "index",
    path: indexpr2YVs6ZwdMeta?.path ?? "/",
    meta: indexpr2YVs6ZwdMeta || {},
    alias: indexpr2YVs6ZwdMeta?.alias || [],
    redirect: indexpr2YVs6ZwdMeta?.redirect || undefined,
    component: () => import("C:/WORK/gits/jovsky/pages/index.vue").then(m => m.default || m)
  }
]